// eslint-disable-next-line no-redeclare,no-unused-vars
function setObjectValue(object, subs, value) {
  if (!object) object = {};
  var tmp = object;
  var last = subs.length - 1;
  $.each(subs, function (i, sub) {
    if (i == last) {
      tmp[sub] = value;
      return;
    } else if (!tmp[sub]) tmp[sub] = {};
    tmp = tmp[sub];
  });
}
